import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';

import { Auth, Signer } from 'aws-amplify';

import log from 'loglevel';

import { standardizeUser } from './UserContext';

const cognitoUri = 'https://3swrmk9svk.execute-api.us-east-1.amazonaws.com/RulerGraphQLServer';
const iamUri = 'https://dhf8gmlnta.execute-api.us-east-1.amazonaws.com/RulerGraphQLServer';

async function awsGraphqlFetch(uri, options) {
    const user = await Auth.currentAuthenticatedUser();
    const standardUser = standardizeUser(user);
    if (standardUser.expires_at < Date.parse(new Date())) {
        log.info('Credentials expired; logging user out');
        await Auth.signOut({ global: true });
        const search = `?nextPage=${window.location.pathname}${window.location.search}`;
        window.location.assign(`/signIn${search}`);
    }
    if (standardUser.type === 'Cognito') {
        const result = await Auth.currentSession();
        const idToken = result.getIdToken();
        const idJwt = idToken.getJwtToken();
        // eslint-disable-next-line no-param-reassign
        options.headers.Authorization = idJwt;
        return fetch(cognitoUri, options);
    }
    if (standardUser.type === 'Google') {
        const serviceInfo = {
            region: 'us-east-1', service: 'execute-api',
        };
        const currentCredentials = await Auth.currentCredentials();
        const credentials = {
            access_key: currentCredentials.accessKeyId,
            secret_key: currentCredentials.secretAccessKey,
            session_token: currentCredentials.sessionToken,
        };

        // cognitoemail and cognitouserid are passed to the graphql lambda
        // function in lieu of the cognito authorizer setting identity info.
        // Todo: make this more secure, probably by adding these each query.
        // eslint-disable-next-line no-param-reassign
        options.headers.cognitoemail = standardUser.attributes.email;
        // eslint-disable-next-line no-param-reassign
        options.headers.cognitouserid = standardUser.attributes.sub;
        const signable = {
            url: iamUri,
            method: options.method,
            body: options.body,
            headers: { ...options.headers },
        };
        const signedUrl = Signer.signUrl(signable, credentials, serviceInfo);
        return fetch(signedUrl, options);
    }
    log.error(`Unknown user type: ${standardUser.type}`);
    return fetch(cognitoUri, options);
}

const apolloClient = new ApolloClient({
    link: new HttpLink({
        uri: iamUri,
        fetch: awsGraphqlFetch,
    }),
    cache: new InMemoryCache(),
});

export default apolloClient;
