import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { AppName } from './Constants';
import SignOutButton from './SignOutButton';
import useUserContext from './UserContext';

function renderUserData(context) {
    if (context) {
        let givenName = '';
        let familyName = '';
        let name = '';
        if (context.attributes) {
            if (context.attributes.given_name) {
                givenName = context.attributes.given_name;
            }
            if (context.attributes.family_name) {
                familyName = context.attributes.family_name;
            }
        }

        // Below handles edge case where amplify allows empty givenName and familyname
        // This lets the user access their account to change it otherwise the account
        // change UX is not visible in the UX unless the link is known.
        // Will remove once we have a fix here for amplify

        if (givenName === '' && familyName === '') {
            if (context.attributes && context.attributes.email) {
                name = context.attributes.email;
            } else {
                name = `${AppName} User`;
            }
        } else if (givenName || familyName) {
            name = `${givenName} ${familyName}`;
            name = name.trim();
        }
        return (
            <>
                {name && (
                    <Nav.Link
                      className="mr-2"
                      href="/account"
                    >
                        {name}
                    </Nav.Link>
                )}
                <SignOutButton />
            </>
        );
    }
    return (
        <Button
          variant="danger"
          style={{ color: 'white' }}
          onClick={() => window.location.assign('/signIn')}
        >
            Sign In
        </Button>
    );
}

function NavigationBar(/* props */) {
    const context = useUserContext();
    return (
        <Navbar
          fixed="top"
          bg="primary"
          variant="dark"
          expand="md"
          className="pb-3 pr-4"
        >
            <Navbar.Brand href="/">{AppName}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                { context && (
                    <>
                        <Nav />
                    </>
                )}
                <Nav className="ml-auto justify-content-end">
                    {renderUserData(context)}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

NavigationBar.propTypes = {
    // No props are passed to NavigationBar
};

export default NavigationBar;
