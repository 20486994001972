import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { CompanyName } from './Constants';

function Footer(/* props */) {
    return (
        <footer className="mt-2 fixed-bottom">
            <Navbar bg="light" color="dark">
                <Nav className="mr-auto">
                    <Nav.Link href="/about">
                        About
                    </Nav.Link>
                    <Nav.Link href="/GettingStarted">
                        Getting Started
                    </Nav.Link>
                </Nav>
                <Nav>
                    <Navbar.Text>
                        Copyright: ©
                        { ' ' }
                        {CompanyName}
                        .
                    </Navbar.Text>
                </Nav>
            </Navbar>
        </footer>
    );
}

export default Footer;
