import { Auth } from 'aws-amplify';

import log from 'loglevel';

import Button from 'react-bootstrap/Button';

async function handleSignOutClick() {
    try {
        await Auth.signOut({ global: true });
        window.location.assign('/signIn');
    } catch (err) {
        log.error(err);
    }
}

function SignOutButton(/* props */) {
    return (
        <Button
          style={{ color: 'white' }}
          variant="danger"
          name="signOut"
          value="Sign Out"
          onClick={handleSignOutClick}
        >
            Sign Out
        </Button>
    );
}

export default SignOutButton;
