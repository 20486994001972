import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import LoadingSpinner from './LoadingSpinner';

function ProtectedRoute(props) {
    const [loading, setLoading] = useState(true);
    const [authState, setAuthState] = useState('');
    const Component = props.component;
    const isAuthenticated = authState === 'authenticated';

    useEffect(async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setAuthState('authenticated');
        } catch (err) {
            setAuthState('unauthorized');
        }
        setLoading(false);
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    const destination = props.path === '/' ? '/about' : '/signIn';
    const search = `?nextPage=${props.location.pathname}${props.location.search}`;
    return isAuthenticated ? (
        <Component match={props.computedMatch} />
    ) : (
        <Redirect to={{ pathname: destination, search }} />
    );
}

ProtectedRoute.propTypes = {
    // eslint-disable-next-line
    component: PropTypes.object.isRequired,
    // eslint-disable-next-line
    computedMatch: PropTypes.object,
    path: PropTypes.string.isRequired,
    location: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

ProtectedRoute.defaultProps = {
    computedMatch: null,
    location: '',
};

export default ProtectedRoute;
