import { useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import log from 'loglevel';

import { standardizeUser } from './UserContext';

export const setGAUserId = (userId) => {
    log.info(`Setting userId to ${userId}`);
    ReactGA.set({ userId });
};

export const configGA = (callback) => {
    Auth.currentAuthenticatedUser().then((user) => {
        const standardUser = standardizeUser(user);
        setGAUserId(standardUser.attributes.sub);
        if (callback) callback(user);
    });
};

export const initializeGA = () => {
    ReactGA.initialize('UA-190118954-1', {
        debug: false, // Set to true to see verbose debugging info
        titleCase: false,
        gaOptions: {
            siteSpeedSampleRate: 100, // Todo: decrease as usage increases
        },
    });
};

export const logPageView = (location) => {
    let page = window.location.pathname;
    if (location && location.pathname) {
        page = location.pathname;
    }
    if (process.env.NODE_ENV === 'development') {
        log.info(`In prod would log page view for ${page}`);
    } else {
        ReactGA.set({ page });
        ReactGA.pageview(page);
        log.info(`Page View logged for: ${page}`);
    }
};

export const logModalView = (modalName) => {
    if (process.env.NODE_ENV === 'development') {
        log.info(`In prod would log modal view for ${modalName}`);
    } else {
        ReactGA.modalview(modalName);
        log.info(`Modal View logged for: ${modalName}`);
    }
};

export const logEvent = (category, action, label, value, nonInteraction) => {
    // See https://www.npmjs.com/package/react-ga for details
    const event = {
        category,
        action,
        label,
        value,
        nonInteraction,
    };
    if (process.env.NODE_ENV === 'development') {
        log.info(`In prod would log event for ${event}`);
    } else {
        ReactGA.event(event);
        log.info(`Event logged for: ${event}`);
    }
};

export const usePageViews = () => {
    // eslint-disable-next-line prefer-const
    let location = useLocation();
    useEffect(() => {
        logPageView(location);
    }, [location]);
};

// Todo: use as the reporting function for web vitals
export const sendToGoogleAnalytics = ({ name, delta, id }) => {
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).

    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    // Use a non-interaction event to avoid affecting bounce rate.
    logEvent('Web Vitals', name, id, Math.round(name === 'CLS' ? delta * 1000 : delta), true);
};
