const AppName = 'Ruler';
const CompanyName = 'Erdos Labs';
const GoogleCacheKey = 'LoggedInViaGoogle';
const MediaPath = 'https://www.erdoslabs.com/static/media/';
const NewUserKey = '##NewUser##';

export {
    AppName,
    CompanyName,
    GoogleCacheKey,
    MediaPath,
    NewUserKey,
};
