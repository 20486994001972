import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';

// NB - the alert bar scrolls. If we want to make it sticky we can use
// style={{ position: 'fixed', width: '100%' }} but the Amplify Login UI floats
// so that'll appear over top of it. Maybe that's better than not seeing it though?

function AlertBar(props) {
    const classString = props.inContainer ? 'my-2' : 'pt-5 my-2';
    if (props.message) {
        return (
            <Alert
              className={classString}
              variant="danger"
            >
                {!props.inContainer && (<br />)}
                {props.message}
            </Alert>
        );
    }
    return null;
}

AlertBar.propTypes = {
    message: PropTypes.string.isRequired,
    inContainer: PropTypes.bool,
};

AlertBar.defaultProps = {
    inContainer: false,
};

export default AlertBar;
