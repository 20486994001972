import {
    AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignUp, AmplifySignIn,
} from '@aws-amplify/ui-react';

import { AppName } from './Constants';

const federated = {
    googleClientId: '1086059838322-us1hl4o00bcl5h7hea6p2k8pg2nmele9.apps.googleusercontent.com',
};

function SignIn(/* props */) {
    const initialState = window.location.search.indexOf('signup') > -1 ? 'signup' : 'signin';
    return (
        <AmplifyAuthContainer>
            <AmplifyAuthenticator
              usernameAlias="email"
              initialAuthState={initialState}
              federated={federated}
              hideToast
            >
                <AmplifySignUp
                  headerText={`Create your ${AppName} account`}
                  slot="sign-up"
                  usernameAlias="email"
                  formFields={[
                      {
                          type: 'email',
                          label: 'Email Address',
                          required: 'true',
                          inputProps: { required: true },
                      },
                      {
                          type: 'password',
                          label: 'Password',
                          required: 'true',
                          hint: 'At least 6 characters. Must contain a number, lower and uppercase and a symbol.',
                          // RegEx matches default Amazon cognito password complexity.
                          // eslint-disable-next-line no-useless-escape
                          inputProps: { required: true, pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+`~{}\[\];:\'"\\|\/<>,\.\?\(\)-]).{6,99}$' },
                      },
                      {
                          type: 'given_name',
                          label: 'First Name',
                          // Set required flag, though there is an amplify bug saying it is ignored
                          required: 'true',
                          placeholder: 'First Name',
                          inputProps: { required: true },
                      },
                      {
                          type: 'family_name',
                          label: 'Last Name',
                          required: 'true',
                          placeholder: 'Last Name',
                          inputProps: { required: true },
                      },
                  ]}
                />
                <AmplifySignIn
                  headerText={`Sign in to ${AppName}`}
                  federated={federated}
                  slot="sign-in"
                />
            </AmplifyAuthenticator>
        </AmplifyAuthContainer>
    );
}

export default SignIn;
