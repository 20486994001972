import PropTypes from 'prop-types';

import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner(props) {
    let { message } = props;
    const { noMessage } = props;
    let className = '';

    if (noMessage) {
        className = 'sr-only';
        message = '';
    }

    return (
        <span>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            &nbsp;
            <span className={className}>{message}</span>
            &nbsp;
        </span>
    );
}

LoadingSpinner.propTypes = {
    message: PropTypes.string,
    noMessage: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
    message: 'Loading...',
    noMessage: false,
};

export default LoadingSpinner;
