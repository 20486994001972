import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import LoadingSpinner from './LoadingSpinner';
import ProtectedRoute from './ProtectedRoute';

const About = lazy(() => import('./About'));
const Account = lazy(() => import('./Account'));
const Demo = lazy(() => import('./Demo'));
const GettingStarted = lazy(() => import('./GettingStarted'));
const Home = lazy(() => import('./Home'));
const SignIn = lazy(() => import('./SignIn'));
const NoMatch = lazy(() => import('./NoMatch'));
const Rating = lazy(() => import('./Rating'));
const RatingItem = lazy(() => import('./RatingItem'));

function Routes(/* props */) {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Switch>
                <ProtectedRoute
                  exact
                  path="/"
                  component={Home}
                />
                <ProtectedRoute
                  exact
                  path="/account"
                  component={Account}
                />
                <ProtectedRoute
                  exact
                  path="/demo"
                  component={Demo}
                />
                <ProtectedRoute
                  path="/measure/:ratableId/:ratingDate"
                  component={RatingItem}
                />
                <ProtectedRoute
                  path="/measure/:ratableId"
                  component={Rating}
                />
                <Route
                  exact
                  path="/about"
                  component={About}
                />
                <Route
                  exact
                  path="/GettingStarted"
                  component={GettingStarted}
                />
                <Route
                  exact
                  path="/signIn"
                  component={SignIn}
                />
                <Route
                  path="*"
                  component={NoMatch}
                />
            </Switch>
        </Suspense>
    );
}

export default Routes;
