import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

// NB - we may need to extend the vocabulary for meta and links. E.g. if we want to pass
// icon sizes. Same goes if we want to set other tags.

function Metadata(props) {
    return (
        <Helmet>
            {props.title && <title>{props.title}</title>}
            {props.links.map((link) => (
                <link rel={link.rel} href={link.href} />
            ))}
            {props.metas.map((meta) => (
                <meta name={meta.name} property={meta.property} content={meta.content} />
            ))}
        </Helmet>
    );
}

Metadata.propTypes = {
    title: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.object),
    metas: PropTypes.arrayOf(PropTypes.object),
};

Metadata.defaultProps = {
    title: '',
    links: [],
    metas: [],
};

export default Metadata;
