const region = 'us-east-1';

const amplifyConfig = {
    aws_project_region: region,
    aws_cognito_identity_pool_id:
        'us-east-1:22725d18-64c3-4526-a0fc-c136f2830350',
    aws_user_pools_id: 'us-east-1_dHHv4bkue',
    aws_cognito_region: region,
    aws_user_pools_web_client_id: '46uvkip7ja6himlhfeoduefomr',
};

export default amplifyConfig;
