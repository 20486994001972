import React, { useContext } from 'react';

import { Cache } from 'aws-amplify';

import { GoogleCacheKey, NewUserKey } from './Constants';

const UserContext = React.createContext(null);

function getGoogleLoginStatus() {
    return Cache.getItem(GoogleCacheKey);
}

function setGoogleLoginStatus(value) {
    Cache.setItem(GoogleCacheKey, value);
}

function clearGoogleLoginStatus() {
    Cache.removeItem(GoogleCacheKey);
}

function useUserContext() {
    return useContext(UserContext);
}

function standardizeUser(user) {
    if (!user) return user;
    const standardUser = {};
    if (user.attributes && user.attributes.sub) { // Cognito user
        standardUser.attributes = user.attributes;
        standardUser.attributes.given_name = standardUser.attributes.given_name || '';
        standardUser.attributes.family_name = standardUser.attributes.family_name || '';
        standardUser.type = 'Cognito';
    } else if ('username' in user) { // unconfirmed Cognito user
        standardUser.attributes = {
            sub: '',
            email: user.username,
            given_name: '',
            family_name: '',
        };
    } else { // Google user
        const federatedInfo = JSON.parse(window.localStorage.getItem('aws-amplify-federatedInfo'));
        const parts = user.name.split(' ');
        let givenName = '';
        let familyName = user.name;
        if (parts.length > 1) {
            // eslint-disable-next-line prefer-destructuring
            givenName = parts[0];
            familyName = parts.slice(1).join(' ');
        }
        standardUser.attributes = {
            sub: user.id,
            email: user.email,
            given_name: givenName,
            family_name: familyName,
        };
        standardUser.type = 'Google';
        // NB - the token below is a Google JWT token
        standardUser.token = federatedInfo.token;
        standardUser.expires_at = federatedInfo.expires_at;
        standardUser.cached = getGoogleLoginStatus();
        if (!standardUser.cached || standardUser.cached === NewUserKey) {
            setGoogleLoginStatus(NewUserKey);
            standardUser.cached = NewUserKey;
        } else {
            // Set the cache key so we know not to create the User object later
            setGoogleLoginStatus(standardUser.attributes.email);
        }
    }
    return standardUser;
}

export default useUserContext;

export {
    clearGoogleLoginStatus,
    getGoogleLoginStatus,
    setGoogleLoginStatus,
    standardizeUser,
    UserContext,
};
