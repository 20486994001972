import { useEffect, useState } from 'react';
import { ApolloProvider, useMutation } from '@apollo/client';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import Amplify, { Auth, Hub } from 'aws-amplify';
import log from 'loglevel';

import Container from 'react-bootstrap/Container';

import './App.css';
import amplifyConfig from './AmplifyConfig';

import AlertBar from './AlertBar';
import { configGA, initializeGA, usePageViews } from './analyticsTracker';
import apolloClient from './ApolloClient';
import { AppName, NewUserKey } from './Constants';
import Footer from './Footer';
import Metadata from './Metadata';
import NavigationBar from './NavigationBar';
import { addUserMutation } from './Queries';
import Routes from './Routes';
import SignIn from './SignIn';
import { setGoogleLoginStatus, standardizeUser, UserContext } from './UserContext';

// eslint-disable-next-line
process.env.NODE_ENV === 'development'
    ? log.setLevel('trace')
    : log.setLevel('error');

Amplify.configure(amplifyConfig);

// Todo: the way we protect routes is fragile. It relies on
// Amplify posting certain messages to their event bus but those
// don't seem stable since they changed when we fiddled with the
// config that solved the problem of not being able to get the Auth
// credentials after Login.

function potentiallyAddUser(standardUser, nextAuthState, possiblyAddUser) {
    if (standardUser && standardUser.type !== 'Cognito'
        && (nextAuthState === '' || nextAuthState === 'signedin')
        && (!standardUser.cached || standardUser.cached !== standardUser.attributes.email
            || standardUser.cached === NewUserKey)) {
        log.info('Creating User object');
        const userId = `IdentityProvider#${standardUser.type}#${standardUser.attributes.email}`;
        possiblyAddUser({
            variables: {
                Email: standardUser.attributes.email,
                UserId: userId,
                GivenName: standardUser.attributes.given_name,
                FamilyName: standardUser.attributes.family_name,
                IdentityProvider: standardUser.type,
            },
        });
        setGoogleLoginStatus(standardUser.attributes.email);
    }
}

function App(/* props */) {
    const [possiblyAddUser] = useMutation(addUserMutation,
        { client: apolloClient });
    const [alertMessage, setAlertMessage] = useState('');
    const [amplifyUser, setAmplifyUser] = useState(null);
    const [loading, setLoading] = useState(true);

    initializeGA();
    usePageViews();

    useEffect(() => {
        function listener(data) {
            let message = '';
            switch (data.payload.event) {
                case 'ToastAuthError':
                    message = data.payload.message;
                    break;
                default:
                    break;
            }
            if (message) {
                setAlertMessage(message);
            }
        }

        function handleCredentialsSet(/* user */) {
            setLoading(false);
        }

        function redirectUponSignIn() {
            // Redirect to home page upon sign in unless there is a nextPage search param
            const pathname = window.location.pathname.toLowerCase();
            const nextPageString = 'nextPage=';
            const nextPageIndex = window.location.search.indexOf(nextPageString);
            if (nextPageIndex > -1) {
                const nextPage = window.location.search.substr(
                    nextPageIndex + nextPageString.length,
                );
                window.location.assign(nextPage);
            } else if (pathname === '/signin' || pathname === '/signin/') {
                window.location.assign('/');
            }
        }

        Hub.listen('UI Auth', listener);
        Hub.listen('auth', listener);

        onAuthUIStateChange((nextAuthState, authData) => {
            const standardUser = standardizeUser(authData);
            setAmplifyUser(standardUser);
            potentiallyAddUser(standardUser, nextAuthState, possiblyAddUser);
            setAlertMessage('');
            if (nextAuthState === AuthState.SignedIn) {
                configGA(handleCredentialsSet);
                redirectUponSignIn();
            } else {
                setLoading(false);
            }
        });

        // Hack to fix safari "blank page" bug from
        // https://github.com/aws-amplify/docs/issues/2895#issuecomment-699132676
        if (amplifyUser === null) {
            Auth.currentAuthenticatedUser().then((authData) => {
                const standardUser = standardizeUser(authData);
                setAmplifyUser(standardizeUser(standardUser));
                potentiallyAddUser(standardUser, null, possiblyAddUser);
                setAlertMessage('');
                configGA(handleCredentialsSet);
                redirectUponSignIn();
            }).catch((err) => {
                log.info(err);
            });
        }
    }, []);

    return (
        <>
            <Metadata title={AppName} />
            <ApolloProvider client={apolloClient}>
                <UserContext.Provider value={amplifyUser}>
                    <NavigationBar />
                    <AlertBar message={alertMessage} />
                    <Container className="py-5 my-4">
                        { !loading ? (
                            <Routes />
                        ) : (
                            <SignIn />
                        ) }
                    </Container>
                    <Footer />
                </UserContext.Provider>
            </ApolloProvider>
        </>
    );
}

export default App;
