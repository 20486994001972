import { gql, useQuery } from '@apollo/client';

const addRatableMutation = gql`
    mutation addRatable(
        $RatableId: String!,
        $IdOrOwner: String!,
        $RatableName: String!,
        $RatableUrl: String!,
        $Anonymous: Boolean!,
        $Closed: Boolean!,
        $Permissions: [String]!,
        $Participants: [String]!,
        $When: String!,
        $CreatedDate: String!,
        $LastNotification: String!,
        $CreatorName: String!,
        $CreatorId: String!,
        $Description: String!,
        $FinalComment: String!,
    ) {
        addRatable(
            RatableId: $RatableId,
            IdOrOwner: $IdOrOwner,
            RatableName: $RatableName,
            RatableUrl: $RatableUrl,
            Anonymous: $Anonymous,
            Closed: $Closed,
            Permissions: $Permissions,
            Participants: $Participants,
            When: $When,
            CreatedDate: $CreatedDate,
            LastNotification: $LastNotification,
            CreatorName: $CreatorName,
            CreatorId: $CreatorId,
            Description: $Description,
            FinalComment: $FinalComment,
        ) {
            RatableId
            IdOrOwner
            RatableName
            RatableUrl
            Anonymous
            Closed
            Permissions
            Participants
            When
            CreatedDate
            LastNotification
            CreatorName
            CreatorId
            Description
            FinalComment
        }
    }
`;

const addUserMutation = gql`
    mutation addUser(
        $UserId: String!,
        $Email: String!,
        $GivenName: String!,
        $FamilyName: String!,
        $IdentityProvider: String!,
    ) {
        addUser(
            UserId: $UserId,
            Email: $Email,
            GivenName: $GivenName,
            FamilyName: $FamilyName,
            IdentityProvider: $IdentityProvider) {
                UserId
                Email
                GivenName
                FamilyName
                IdentityProvider
        }
    }
`;

const closeRatableMutation = gql`
    mutation closeRatable($RatableId: String!, $FinalComment: String!) {
        closeRatable(RatableId: $RatableId, FinalComment: $FinalComment) {
            RatableId
        }
    }
`;

const deleteRatableMutation = gql`
    mutation deleteRatable($RatableId: String!) {
        deleteRatable(RatableId: $RatableId) {
            RatableId
        }
    }
`;

const deleteUserMutation = gql`
    mutation deleteUser($Email: String!, $UserId: String!) {
        deleteUser(Email: $Email, UserId: $UserId) {
            Email
            UserId
        }
    }
`;

const updateRatingMutation = gql`
    mutation updateRating(
        $RatableId: String!,
        $RaterId: String!,
        $RatingDate: String!
        $RatingComment: String!,
        $Rating: Int!,
        $RatingLoggedDate: String!,
        $RaterName: String!,
    ) {
        updateRating(
            RatableId: $RatableId,
            RaterId: $RaterId,
            RatingDate: $RatingDate,
            RatingComment: $RatingComment,
            Rating: $Rating,
            RatingLoggedDate: $RatingLoggedDate,
            RaterName: $RaterName,
        ) {
            RatableId
            RaterId
            RatingDate
            Rating
            RatingComment
            RatingLoggedDate
            RaterName
        }
    }
`;

const updateUserNameMutation = gql`
    mutation updateUserName(
        $UserId: String!,
        $Email: String!,
        $GivenName: String!,
        $FamilyName: String!,
    ) {
        updateUserName(
            UserId: $UserId,
            Email: $Email,
            GivenName: $GivenName,
            FamilyName: $FamilyName,
        ) {
            UserId
            Email
            GivenName
            FamilyName
        }
    }
`;

const updateUserOptOutMutation = gql`
    mutation updateUserOptOut(
        $UserId: String!,
        $Email: String!,
        $NotificationOptOut: Boolean!,
    ) {
        updateUserOptOut(
            UserId: $UserId,
            Email: $Email,
            NotificationOptOut: $NotificationOptOut,
        ) {
            UserId
            Email
            NotificationOptOut
        }
    }
`;

const ratablesFromUserQuery = gql`
    query getRatablesFromUserQuery($UserId: String!) {
        getRatablesFromUserQuery(UserId: $UserId) {
            RatableId
            IdOrOwner
            RatableName
            RatableUrl
            Anonymous
            Closed
            Permissions
            Participants
            When
            CreatedDate
            LastNotification
            CreatorName
            CreatorId
            Description
            FinalComment
        }
    }
`;

function useRatablesFromUserQuery(userId) {
    return useQuery(
        ratablesFromUserQuery,
        { variables: { UserId: userId } },
    );
}

const ratableFromIdQuery = gql`
    query getRatableFromIdQuery($RatableId: String!) {
        getRatableFromIdQuery(RatableId: $RatableId) {
            RatableId
            IdOrOwner
            RatableName
            RatableUrl
            Anonymous
            Closed
            Permissions
            Participants
            When
            CreatedDate
            LastNotification
            CreatorName
            CreatorId
            Description
            FinalComment
        }
    }
`;

function useRatableFromIdQuery(ratableId) {
    return useQuery(
        ratableFromIdQuery,
        { variables: { RatableId: ratableId } },
    );
}

const ratingFromIdAndDateQuery = gql`
    query getRatingFromIdAndDateQuery($RatableId: String!, $RatingDate: String!) {
        getRatingFromIdAndDateQuery(RatableId: $RatableId, RatingDate: $RatingDate) {
            RatableId
            RatingDateRaterId
            RaterId
            RatingDate
            Rating
            RatingComment
            RatableIdRatingDate
            RatingLoggedDate
            RaterName
            RatableName
            SentReminder
        }
    }
`;

function useRatingFromIdAndDateQuery(ratableId, ratingDate) {
    return useQuery(
        ratingFromIdAndDateQuery,
        { variables: { RatableId: ratableId, RatingDate: ratingDate } },
    );
}

const ratingsFromIdQuery = gql`
    query getRatingsFromIdQuery($RatableId: String!) {
        getRatingsFromIdQuery(RatableId: $RatableId) {
            RatableId
            RatingDateRaterId
            RaterId
            RatingDate
            Rating
            RatingComment
            RatableIdRatingDate
            RatingLoggedDate
            RaterName
            RatableName
            SentReminder
        }
    }
`;

function useRatingsFromIdQuery(ratableId) {
    return useQuery(
        ratingsFromIdQuery,
        { variables: { RatableId: ratableId } },
    );
}

const ratingsFromRaterQuery = gql`
    query getRatingsFromRaterQuery($RaterId: String!) {
        getRatingsFromRater(RaterId: $RaterId) {
            RatableId
            RatingDateRaterId
            RaterId
            RatingDate
            Rating
            RatingComment
            RatableIdRatingDate
            RatingLoggedDate
            RaterName
            RatableName
            SentReminder
        }
    }`;

function useRatingsFromRaterQuery(raterId) {
    return useQuery(
        ratingsFromRaterQuery,
        { variables: { RaterId: raterId } },
    );
}

const userQuery = gql`
    query getUser(
        $UserId: String!,
        $Email: String!,
        $GivenName: String!,
        $FamilyName: String!,
        $IdentityProvider: String!) {
        userFromId(
            Email: $Email,
            UserId: $UserId,
            GivenName: $GivenName,
            FamilyName: $FamilyName,
            IdentityProvider: $IdentityProvider) {
            UserId
            Email
            GivenName
            FamilyName
            Organization
            GoogleAccessToken
            NotificationOptOut
        }
}
`;

function useUserQuery(userId, email, givenName, familyName, identityProvider) {
    return useQuery(
        userQuery,
        {
            variables: {
                UserId: userId,
                Email: email,
                GivenName: givenName,
                FamilyName: familyName,
                IdentityProvider: identityProvider,
            },
        },
    );
}

export {
    addRatableMutation,
    addUserMutation,
    closeRatableMutation,
    deleteRatableMutation,
    deleteUserMutation,
    updateRatingMutation,
    updateUserNameMutation,
    updateUserOptOutMutation,
    useRatableFromIdQuery,
    useRatablesFromUserQuery,
    useRatingFromIdAndDateQuery,
    useRatingsFromRaterQuery,
    useRatingsFromIdQuery,
    useUserQuery,
};
